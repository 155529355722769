.contentBlock {
  width: 100vw;
}

.ant-checkbox-group {
  display: flex;
  flex-direction: column !important;
}

.btnDisabled {
  width: calc(100%);
}

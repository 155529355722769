.globalBlock {
  width: 100%;
  height: calc(100vh - (64px));
  align-items: center;
  justify-content: center;
  margin: auto;
}

.middleBlock {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 600px;
  margin-top: 150px;
}

.formWidth {
  width: 600px;
}

.rememberText {
  margin-left: 160px;
}

.renderPositionAlert {
  width: 330px;
  margin: auto;
  margin-bottom: 30px;
  position: relative;
  left: 40px;
}

@media only screen and (max-width: 600px) {
  .middleBlock {
    width: 400px;
  }

  .formWidth {
    margin: auto;
    width: 80%;
  }

  .rememberText {
    margin-left: 0px;
  }

  .renderPositionAlert {
    width: 300px;
    left: 0px;
  }
}

@media only screen and (min-width: 2000px) {
  .globalBlock {
    padding-top: 100px;
  }
}
